<template>
  <ValidationObserver
    v-slot="{ invalid, passes }"
  >
    <v-form class="reset-password">
      <template v-if="passwordResetSuccess">
        <v-row
          justify="center"
          no-gutters
        >
          <v-col cols="auto">
            <v-icon
              color="success"
              large
            >
              fal fa-check-circle
            </v-icon>
          </v-col>
        </v-row>
        <v-row
          class="mt-6"
          justify="center"
          no-gutters
        >
          <v-col cols="auto">
            <span class="primary--text subtitle-2">
              {{ $t('headlines.resetPasswordSuccess') }}
            </span>
          </v-col>
        </v-row>
        <v-row
          class="mb-n4 mt-6 mx-n4"
          justify="center"
          no-gutters
        >
          <v-col>
            <p class="body-2">
              {{ $t('descriptions.redirectToLogin', { remainingSeconds: redirectCountDown }) }}
            </p>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row
          justify="center"
          no-gutters
        >
          <v-col cols="auto">
            <v-icon
              color="error"
              large
            >
              fal fa-exclamation-triangle
            </v-icon>
          </v-col>
        </v-row>
        <v-row
          class="mt-6"
          justify="center"
          no-gutters
        >
          <v-col cols="auto">
            <span class="primary--text subtitle-2">
              {{ $t('headlines.resetPassword') }}
            </span>
          </v-col>
        </v-row>
        <v-row
          class="mt-8"
          no-gutters
        >
          <v-col>
            <VeeTextField
              v-model="newPassword"
              :append-icon="showPassword ? 'far fa-eye' : 'far fa-eye-slash'"
              dense
              :label="$t('labels.passwordNew')"
              name="newPassword"
              outlined
              :rules="{ max: 32, passwordRegex: passwordRegex, required: true}"
              :type="showPassword ? 'text' : 'password'"
              vid="newPassword"
              @click:append="showPassword = !showPassword"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <VeeTextField
              v-model="newPasswordConfirm"
              :append-icon="showPassword ? 'far fa-eye' : 'far fa-eye-slash'"
              dense
              :label="$t('labels.passwordNewConfirm')"
              name="newPasswordConfirm"
              outlined
              rules="required|passwordConfirmed:newPassword"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              @keyup.enter.prevent="passes(resetPassword)"
            />
          </v-col>
        </v-row>
        <v-row
          class="mt-4"
          no-gutters
        >
          <v-col>
            <v-btn
              block
              class="password-reset"
              color="secondary"
              :disabled="invalid || !hasValidResetUrl"
              @click.prevent="passes(resetPassword)"
            >
              <v-progress-circular
                v-if="requestInProgress"
                color="white"
                indeterminate
                size="20"
                width="2"
              />
              <span v-else>
                {{ $t('labels.submit') }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <v-row
        justify="center"
        class="mt-6"
        no-gutters
      >
        <v-col cols="auto">
          <router-link
            class="text-body-2"
            :to="{ name: 'login' }"
          >
            {{ $t('labels.backToLogin') }}
          </router-link>
        </v-col>
      </v-row>
    </v-form>
  </ValidationObserver>
</template>

<script>
import _ from 'lodash';
import { PWD_REGEX } from '@/components/form_controls/constants';
import { showStatusOnLoginPage } from '@/plugins/vue-notification';
import VeeTextField from '@/components/form_controls/VeeTextField';

export default {
  components: {
    VeeTextField
  },

  props: {
    uid: String, // eslint-disable-line vue/require-default-prop
    resetToken: String // eslint-disable-line vue/require-default-prop
  },

  data () {
    return {
      newPassword: '',
      newPasswordConfirm: '',
      passwordRegex: PWD_REGEX,
      passwordResetSuccess: false,
      redirectCountDown: 0,
      requestInProgress: false,
      showPassword: false
    };
  },

  computed: {
    hasValidResetUrl () {
      // For now we are just checking of uid and password reset token is provided through the URL.
      // Not validating the actual content of the uid and token.
      return !!this.uid && !!this.resetToken;
    }
  },

  methods: {
    resetPassword () {
      const credentials = {
        uid: this.uid,
        token: this.resetToken,
        new_password: this.newPassword,
        re_new_password: this.newPasswordConfirm
      };

      this.requestInProgress = true;
      this.$store.dispatch('auth/resetPasswordConfirm', credentials).then(() => {
        this.passwordResetSuccess = true; // Update this property to show the confirmation UI.
        this.redirectCountDown = 5; // Redirect to login UI after 5 seconds.
        this.startRedirectCountDown();
      }).catch(error => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatusOnLoginPage({
          text: this.$t('descriptions.responseErrorGeneral'),
          type: 'error',
          data
        });
      }).finally(() => {
        this.requestInProgress = false;
      });
    },

    startRedirectCountDown () {
      if (this.redirectCountDown > 0) {
        this.redirectCountDown--;
        setTimeout(this.startRedirectCountDown, 1000);
      } else if (this.redirectCountDown <= 0) {
        this.$router.push({ name: 'login' });
      }
    }
  }
};
</script>
