<template>
  <ValidationObserver
    v-slot="{ invalid, passes }"
  >
    <v-form>
      <v-row
        align="center"
        justify="center"
        no-gutters
      >
        <v-img
          contain
          max-width="55px"
          src="@/assets/images/logo.svg"
        />
      </v-row>
      <v-row
        align="center"
        class="mt-3"
        justify="center"
        no-gutters
      >
        <span class="primary--text text-h6">
          {{ $t('labels.nursebrite') }}
        </span>
      </v-row>
      <v-row
        class="mt-9"
        no-gutters
      >
        <v-col>
          <VeeTextField
            v-model.lazy="email"
            dense
            :label="$t('labels.email')"
            name="email"
            outlined
            rules="email|required|max:255"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <VeeTextField
            v-model="password"
            :append-icon="showPassword ? 'far fa-eye' : 'far fa-eye-slash'"
            dense
            :label="$t('labels.password')"
            name="password"
            outlined
            rules="required|max:32"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            @keyup.enter="passes(login)"
          />
        </v-col>
      </v-row>
      <v-row
        class="mt-n5"
        no-gutters
      >
        <v-col class="align-center d-flex justify-space-between">
          <v-checkbox
            v-model="keepUserLoggedIn"
            color="info"
            dense
            :label="$t('labels.keepUserLoggedIn')"
          />
          <router-link
            class="text-body-2"
            :to="{ name: 'forgotPassword' }"
          >
            {{ $t('labels.forgotPassword') }}
          </router-link>
        </v-col>
      </v-row>
      <v-row
        class="mt-4"
        no-gutters
      >
        <v-col>
          <v-btn
            block
            class="login"
            color="secondary"
            :disabled="invalid || authInProgress"
            @click.prevent="passes(login)"
          >
            <v-progress-circular
              v-if="authInProgress"
              color="white"
              indeterminate
              size="20"
              width="2"
            />
            <span v-else>
              {{ $t('labels.login') }}
            </span>
          </v-btn>
        </v-col>
      </v-row>

      <v-row
        justify="center"
        class="mt-8"
        no-gutters
      >
        <a
          class="text-body-2"
          href="mailto:support@nursebrite.com"
        >
          {{ $t('labels.contactUs') }}
        </a>
      </v-row>
    </v-form>
  </ValidationObserver>
</template>

<script>
import VeeTextField from '@/components/form_controls/VeeTextField';
import { cleanAllStatusOnLoginPage, showStatusOnLoginPage } from '@/plugins/vue-notification';
import AuthServices from '@/services/auth';

export default {
  components: {
    VeeTextField
  },

  data () {
    return {
      authInProgress: false,
      email: '',
      password: '',
      showPassword: false
    };
  },

  computed: {
    keepUserLoggedIn: {
      get () {
        return this.$store.state.auth.keepUserLoggedIn;
      },
      set (newValue) {
        this.$store.dispatch('auth/keepLoggedIn', newValue);
      }
    }
  },

  mounted () {
    if (this.$store.state.auth.logoutReason) {
      switch (this.$store.state.auth.logoutReason) {
        case 'idle':
          this.$dialog.acknowledge({
            body: this.$t('descriptions.idle'),
            confirmText: this.$t('labels.ok')
          }, { persistent: true, width: 350 }).then(() => {
            this.$store.commit('auth/set_logout_reason', '');
          });
          break;
        case 'unauthorized_device':
          this.$dialog.acknowledge({
            title: this.$t('labels.authentication'),
            body: this.$t('descriptions.singleDeviceLogin'),
            bodyIcon: 'fal fa-exclamation-triangle',
            confirmText: this.$t('labels.ok')
          }, { persistent: true, width: 350 }).then(() => {
            this.$store.commit('auth/set_logout_reason', '');
          });
          break;
        case 'no_active_profile':
          this.$dialog.acknowledge({
            body: this.$t('descriptions.noActiveProfile'),
            confirmText: this.$t('labels.ok')
          }, { persistent: true, width: 350 }).then(() => {
            this.$store.commit('auth/set_logout_reason', '');
          });
          break;
      }
    }
  },

  methods: {
    login () {
      this.authInProgress = true;
      this.$store.dispatch('auth/login', { email: this.email, password: this.password }).then(() => {
        this.$router.push(this.$route.query.redirect || '/');
      }).catch(error => {
        if (AuthServices.accountLocked(error.response)) {
          cleanAllStatusOnLoginPage();
        } else {
          let textKey;

          if (AuthServices.credentialInvalid(error.response)) {
            textKey = 'descriptions.loginFailInvalidCredential';
          } else {
            textKey = 'descriptions.loginFailGeneral';
          }

          let data;
          if (error.response && error.response.data && error.response.data.detail) {
            data = { error: error.response.data.detail };
          }

          showStatusOnLoginPage({
            text: this.$t(textKey),
            type: 'error',
            data
          });
        }
      }).finally(() => {
        this.authInProgress = false;
      });
    }
  }
};
</script>

<style lang="scss">
.container.login-page {
  .v-input--checkbox {
    .v-label {
      font-size: 14px;
    }
  }
}
</style>
