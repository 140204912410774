var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var invalid = ref.invalid
          var passes = ref.passes
          return [
            _c(
              "v-form",
              { staticClass: "forgot-password" },
              [
                _vm.emailSent
                  ? [
                      _c(
                        "v-row",
                        { attrs: { justify: "center", "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "success", large: "" } },
                                [
                                  _vm._v(
                                    "\n            fal fa-check-circle\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-6",
                          attrs: { justify: "center", "no-gutters": "" }
                        },
                        [
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _c("p", { staticClass: "body-2" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "descriptions.passwordResetEmailSent"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-4", attrs: { "no-gutters": "" } },
                        [
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _c("span", { staticClass: "body-2" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("descriptions.didNotReceiveEmail")
                                  ) +
                                  "\n          "
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-6", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "grey--text password-reset mt-2 mb-2 px-8 text--darken-3",
                                  attrs: {
                                    block: "",
                                    color: "grey",
                                    disabled: invalid,
                                    outlined: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.emailSent = false
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("labels.tryAgain")) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  : [
                      _c(
                        "v-row",
                        { attrs: { justify: "center", "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "error", large: "" } },
                                [
                                  _vm._v(
                                    "\n            fal fa-exclamation-triangle\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-6",
                          attrs: { justify: "center", "no-gutters": "" }
                        },
                        [
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _c(
                              "span",
                              { staticClass: "primary--text subtitle-2" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("headlines.forgotPassword")) +
                                    "\n          "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-6",
                          attrs: { justify: "center", "no-gutters": "" }
                        },
                        [
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _c("p", { staticClass: "body-2" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("descriptions.forgotPassword")
                                  ) +
                                  "\n          "
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-5", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("VeeTextField", {
                                attrs: {
                                  dense: "",
                                  label: _vm.$t("labels.email"),
                                  name: "email",
                                  outlined: "",
                                  rules: "email|required|max:255"
                                },
                                on: {
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.preventDefault()
                                    return passes(_vm.requestPasswordReset)
                                  }
                                },
                                model: {
                                  value: _vm.email,
                                  callback: function($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "password-reset",
                                  attrs: {
                                    block: "",
                                    color: "secondary",
                                    disabled: invalid
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return passes(_vm.requestPasswordReset)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("labels.send")) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                _c(
                  "v-row",
                  {
                    staticClass: "mt-6",
                    attrs: { justify: "center", "no-gutters": "" }
                  },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "auto" } },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "text-body-2",
                            attrs: { to: { name: "login" } }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("labels.backToLogin")) +
                                "\n        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              2
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }