<template>
  <v-container class="account-locked pa-0">
    <v-row
      justify="center"
      no-gutters
    >
      <v-col cols="auto">
        <v-icon
          color="error"
          large
        >
          fal fa-exclamation-triangle
        </v-icon>
      </v-col>
    </v-row>
    <v-row
      class="mt-9"
      no-gutters
    >
      <v-col>
        <p class="body-2">
          {{ $t('descriptions.loginFailExcessiveAttempts', { remainingMinutes: remainCooloffTime }) }}
        </p>
      </v-col>
    </v-row>
    <v-row
      class="mt-6"
      justify="center"
      no-gutters
    >
      <v-col>
        <v-btn
          block
          class="primary--text"
          color="primary"
          outlined
          @click="gotoSupport()"
        >
          {{ $t('labels.contactSupport') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      remainCooloffTime: 20 // Web API default is 20 minutes
    };
  },

  created () {
    this.getRemainingCooloffTime();
  },

  methods: {
    getRemainingCooloffTime () {
      const cooloffDueTime = this.$store.state.auth.cooloffDueTime; // Unix timestamp in seconds.
      const currentTime = Math.round(Date.now() / 1000); // Date.now() returns milliseconds.

      if (cooloffDueTime > currentTime) {
        this.remainCooloffTime = Math.ceil((cooloffDueTime - currentTime) / 60); // Display remaining time in minutes.
      } else {
        // Cooloff due time passed. Update state in store to trigger the re-evaluation of the getter
        // that would allow the login UI to show.
        this.$store.commit('auth/unset_stat_on_cooloff_due_time_passed');
      }
      // Refresh the count down every minute.
      setTimeout(this.getRemainingCooloffTime, 60000);
    },

    gotoSupport () {
      this.$store.dispatch('gotoSupport');
    }
  }
};
</script>
