var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "account-locked pa-0" },
    [
      _c(
        "v-row",
        { attrs: { justify: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("v-icon", { attrs: { color: "error", large: "" } }, [
                _vm._v("\n        fal fa-exclamation-triangle\n      ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-9", attrs: { "no-gutters": "" } },
        [
          _c("v-col", [
            _c("p", { staticClass: "body-2" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("descriptions.loginFailExcessiveAttempts", {
                      remainingMinutes: _vm.remainCooloffTime
                    })
                  ) +
                  "\n      "
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-6", attrs: { justify: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  staticClass: "primary--text",
                  attrs: { block: "", color: "primary", outlined: "" },
                  on: {
                    click: function($event) {
                      return _vm.gotoSupport()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("labels.contactSupport")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }