var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "activate-user-page pa-0",
      class: { mobile: _vm.$vuetify.breakpoint.smAndDown },
      attrs: { "fill-height": "", fluid: "" }
    },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "10", lg: "4", md: "6", sm: "10" } },
            [
              _c(
                "v-card",
                { staticClass: "rounded-lg", attrs: { elevation: "10" } },
                [
                  _vm.activating
                    ? _c(
                        "v-container",
                        { attrs: { "pa-0": "" } },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                align: "center",
                                justify: "center",
                                "no-gutters": ""
                              }
                            },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  color: "info",
                                  indeterminate: "",
                                  size: "40",
                                  width: "3"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "progress-message",
                              attrs: { justify: "center", "no-gutters": "" }
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "descriptions.activateUserInProgress"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-container",
                        { attrs: { "pa-0": "" } },
                        [
                          _vm.activationSuccessful
                            ? _c(
                                "v-container",
                                { attrs: { "pa-0": "" } },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        align: "center",
                                        justify: "center",
                                        "no-gutters": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            color: "success",
                                            "x-large": ""
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                fal fa-check-circle\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        align: "center",
                                        justify: "center",
                                        "no-gutters": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "font-weight-medium ma-9 primary--text text-center"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "descriptions.activateUserSuccess"
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "pb-8",
                                      attrs: { "no-gutters": "" }
                                    },
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-col",
                                        [
                                          _vm.isAuthenticated
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "secondary",
                                                    width: "100%"
                                                  },
                                                  on: { click: _vm.close }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "labels.closeTab"
                                                        )
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            : [
                                                !_vm.scheme ||
                                                _vm.scheme === "https"
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color: "secondary",
                                                          to: { name: "login" },
                                                          width: "100%"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.backToNursebrite"
                                                              )
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  : _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color: "secondary",
                                                          href:
                                                            _vm.scheme +
                                                            "://login",
                                                          width: "100%"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.backToNursebrite"
                                                              )
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                              ]
                                        ],
                                        2
                                      ),
                                      _c("v-spacer")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c(
                                "v-container",
                                { attrs: { "pa-0": "" } },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        align: "center",
                                        justify: "center",
                                        "no-gutters": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            color: "error",
                                            "x-large": ""
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                fal fa-times-circle\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        align: "center",
                                        justify: "center",
                                        "no-gutters": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "font-weight-medium ma-9 primary--text text-center"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "descriptions.activateUserFail"
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "pb-8",
                                      attrs: { "no-gutters": "" }
                                    },
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-col",
                                        [
                                          _vm.isAuthenticated
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "secondary",
                                                    width: "100%"
                                                  },
                                                  on: { click: _vm.close }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "labels.closeTab"
                                                        )
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            : [
                                                !_vm.scheme ||
                                                _vm.scheme === "https"
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color: "secondary",
                                                          to: { name: "login" },
                                                          width: "100%"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.backToNursebrite"
                                                              )
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  : _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color: "secondary",
                                                          href:
                                                            _vm.scheme +
                                                            "://login",
                                                          width: "100%"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.backToNursebrite"
                                                              )
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                              ]
                                        ],
                                        2
                                      ),
                                      _c("v-spacer")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }