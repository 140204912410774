<template>
  <ValidationObserver
    v-slot="{ invalid, passes }"
  >
    <v-form class="forgot-password">
      <template v-if="emailSent">
        <v-row
          justify="center"
          no-gutters
        >
          <v-col cols="auto">
            <v-icon
              color="success"
              large
            >
              fal fa-check-circle
            </v-icon>
          </v-col>
        </v-row>
        <v-row
          class="mt-6"
          justify="center"
          no-gutters
        >
          <v-col cols="auto">
            <p class="body-2">
              {{ $t('descriptions.passwordResetEmailSent') }}
            </p>
          </v-col>
        </v-row>
        <v-row
          class="mt-4"
          no-gutters
        >
          <v-col cols="auto">
            <span class="body-2">
              {{ $t('descriptions.didNotReceiveEmail') }}
            </span>
          </v-col>
        </v-row>
        <v-row
          class="mt-6"
          no-gutters
        >
          <v-col>
            <v-btn
              block
              class="grey--text password-reset mt-2 mb-2 px-8 text--darken-3"
              color="grey"
              :disabled="invalid"
              outlined
              @click.prevent="emailSent = false;"
            >
              {{ $t('labels.tryAgain') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row
          justify="center"
          no-gutters
        >
          <v-col cols="auto">
            <v-icon
              color="error"
              large
            >
              fal fa-exclamation-triangle
            </v-icon>
          </v-col>
        </v-row>
        <v-row
          class="mt-6"
          justify="center"
          no-gutters
        >
          <v-col cols="auto">
            <span class="primary--text subtitle-2">
              {{ $t('headlines.forgotPassword') }}
            </span>
          </v-col>
        </v-row>
        <v-row
          class="mt-6"
          justify="center"
          no-gutters
        >
          <v-col cols="auto">
            <p class="body-2">
              {{ $t('descriptions.forgotPassword') }}
            </p>
          </v-col>
        </v-row>
        <v-row
          class="mt-5"
          no-gutters
        >
          <v-col>
            <VeeTextField
              v-model.lazy="email"
              dense
              :label="$t('labels.email')"
              name="email"
              outlined
              rules="email|required|max:255"
              @keydown.enter.prevent="passes(requestPasswordReset)"
            />
          </v-col>
        </v-row>
        <v-row
          class="mt-3"
          no-gutters
        >
          <v-col>
            <v-btn
              block
              class="password-reset"
              color="secondary"
              :disabled="invalid"
              @click.prevent="passes(requestPasswordReset)"
            >
              {{ $t('labels.send') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <v-row
        justify="center"
        class="mt-6"
        no-gutters
      >
        <v-col cols="auto">
          <router-link
            class="text-body-2"
            :to="{ name: 'login' }"
          >
            {{ $t('labels.backToLogin') }}
          </router-link>
        </v-col>
      </v-row>
    </v-form>
  </ValidationObserver>
</template>

<script>
import _ from 'lodash';
import VeeTextField from '@/components/form_controls/VeeTextField';
import { showStatusOnLoginPage } from '@/plugins/vue-notification';

export default {
  components: {
    VeeTextField
  },

  data () {
    return {
      email: '',
      emailSent: false
    };
  },

  methods: {
    requestPasswordReset () {
      this.$store.dispatch('auth/resetPassword', { email: this.email }).then(() => {
        this.emailSent = true; // Update this property to show the confirmation UI.
      }).catch(error => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatusOnLoginPage({
          text: this.$t('descriptions.responseErrorGeneral'),
          type: 'error',
          data
        });
      });
    }
  }
};
</script>
