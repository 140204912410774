var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var invalid = ref.invalid
          var passes = ref.passes
          return [
            _c(
              "v-form",
              { staticClass: "reset-password" },
              [
                _vm.passwordResetSuccess
                  ? [
                      _c(
                        "v-row",
                        { attrs: { justify: "center", "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "success", large: "" } },
                                [
                                  _vm._v(
                                    "\n            fal fa-check-circle\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-6",
                          attrs: { justify: "center", "no-gutters": "" }
                        },
                        [
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _c(
                              "span",
                              { staticClass: "primary--text subtitle-2" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("headlines.resetPasswordSuccess")
                                    ) +
                                    "\n          "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mb-n4 mt-6 mx-n4",
                          attrs: { justify: "center", "no-gutters": "" }
                        },
                        [
                          _c("v-col", [
                            _c("p", { staticClass: "body-2" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("descriptions.redirectToLogin", {
                                      remainingSeconds: _vm.redirectCountDown
                                    })
                                  ) +
                                  "\n          "
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ]
                  : [
                      _c(
                        "v-row",
                        { attrs: { justify: "center", "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "error", large: "" } },
                                [
                                  _vm._v(
                                    "\n            fal fa-exclamation-triangle\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-6",
                          attrs: { justify: "center", "no-gutters": "" }
                        },
                        [
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _c(
                              "span",
                              { staticClass: "primary--text subtitle-2" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("headlines.resetPassword")) +
                                    "\n          "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-8", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("VeeTextField", {
                                attrs: {
                                  "append-icon": _vm.showPassword
                                    ? "far fa-eye"
                                    : "far fa-eye-slash",
                                  dense: "",
                                  label: _vm.$t("labels.passwordNew"),
                                  name: "newPassword",
                                  outlined: "",
                                  rules: {
                                    max: 32,
                                    passwordRegex: _vm.passwordRegex,
                                    required: true
                                  },
                                  type: _vm.showPassword ? "text" : "password",
                                  vid: "newPassword"
                                },
                                on: {
                                  "click:append": function($event) {
                                    _vm.showPassword = !_vm.showPassword
                                  }
                                },
                                model: {
                                  value: _vm.newPassword,
                                  callback: function($$v) {
                                    _vm.newPassword = $$v
                                  },
                                  expression: "newPassword"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("VeeTextField", {
                                attrs: {
                                  "append-icon": _vm.showPassword
                                    ? "far fa-eye"
                                    : "far fa-eye-slash",
                                  dense: "",
                                  label: _vm.$t("labels.passwordNewConfirm"),
                                  name: "newPasswordConfirm",
                                  outlined: "",
                                  rules:
                                    "required|passwordConfirmed:newPassword",
                                  type: _vm.showPassword ? "text" : "password"
                                },
                                on: {
                                  "click:append": function($event) {
                                    _vm.showPassword = !_vm.showPassword
                                  },
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.preventDefault()
                                    return passes(_vm.resetPassword)
                                  }
                                },
                                model: {
                                  value: _vm.newPasswordConfirm,
                                  callback: function($$v) {
                                    _vm.newPasswordConfirm = $$v
                                  },
                                  expression: "newPasswordConfirm"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-4", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "password-reset",
                                  attrs: {
                                    block: "",
                                    color: "secondary",
                                    disabled: invalid || !_vm.hasValidResetUrl
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return passes(_vm.resetPassword)
                                    }
                                  }
                                },
                                [
                                  _vm.requestInProgress
                                    ? _c("v-progress-circular", {
                                        attrs: {
                                          color: "white",
                                          indeterminate: "",
                                          size: "20",
                                          width: "2"
                                        }
                                      })
                                    : _c("span", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.$t("labels.submit")) +
                                            "\n            "
                                        )
                                      ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                _c(
                  "v-row",
                  {
                    staticClass: "mt-6",
                    attrs: { justify: "center", "no-gutters": "" }
                  },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "auto" } },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "text-body-2",
                            attrs: { to: { name: "login" } }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("labels.backToLogin")) +
                                "\n        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              2
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }