var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var invalid = ref.invalid
          var passes = ref.passes
          return [
            _c(
              "v-form",
              [
                _c(
                  "v-row",
                  {
                    attrs: {
                      align: "center",
                      justify: "center",
                      "no-gutters": ""
                    }
                  },
                  [
                    _c("v-img", {
                      attrs: {
                        contain: "",
                        "max-width": "55px",
                        src: require("@/assets/images/logo.svg")
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "mt-3",
                    attrs: {
                      align: "center",
                      justify: "center",
                      "no-gutters": ""
                    }
                  },
                  [
                    _c("span", { staticClass: "primary--text text-h6" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("labels.nursebrite")) +
                          "\n      "
                      )
                    ])
                  ]
                ),
                _c(
                  "v-row",
                  { staticClass: "mt-9", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      [
                        _c("VeeTextField", {
                          attrs: {
                            dense: "",
                            label: _vm.$t("labels.email"),
                            name: "email",
                            outlined: "",
                            rules: "email|required|max:255"
                          },
                          model: {
                            value: _vm.email,
                            callback: function($$v) {
                              _vm.email = $$v
                            },
                            expression: "email"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      [
                        _c("VeeTextField", {
                          attrs: {
                            "append-icon": _vm.showPassword
                              ? "far fa-eye"
                              : "far fa-eye-slash",
                            dense: "",
                            label: _vm.$t("labels.password"),
                            name: "password",
                            outlined: "",
                            rules: "required|max:32",
                            type: _vm.showPassword ? "text" : "password"
                          },
                          on: {
                            "click:append": function($event) {
                              _vm.showPassword = !_vm.showPassword
                            },
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return passes(_vm.login)
                            }
                          },
                          model: {
                            value: _vm.password,
                            callback: function($$v) {
                              _vm.password = $$v
                            },
                            expression: "password"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { staticClass: "mt-n5", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "align-center d-flex justify-space-between"
                      },
                      [
                        _c("v-checkbox", {
                          attrs: {
                            color: "info",
                            dense: "",
                            label: _vm.$t("labels.keepUserLoggedIn")
                          },
                          model: {
                            value: _vm.keepUserLoggedIn,
                            callback: function($$v) {
                              _vm.keepUserLoggedIn = $$v
                            },
                            expression: "keepUserLoggedIn"
                          }
                        }),
                        _c(
                          "router-link",
                          {
                            staticClass: "text-body-2",
                            attrs: { to: { name: "forgotPassword" } }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("labels.forgotPassword")) +
                                "\n        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { staticClass: "mt-4", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "login",
                            attrs: {
                              block: "",
                              color: "secondary",
                              disabled: invalid || _vm.authInProgress
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return passes(_vm.login)
                              }
                            }
                          },
                          [
                            _vm.authInProgress
                              ? _c("v-progress-circular", {
                                  attrs: {
                                    color: "white",
                                    indeterminate: "",
                                    size: "20",
                                    width: "2"
                                  }
                                })
                              : _c("span", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("labels.login")) +
                                      "\n          "
                                  )
                                ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "mt-8",
                    attrs: { justify: "center", "no-gutters": "" }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "text-body-2",
                        attrs: { href: "mailto:support@nursebrite.com" }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("labels.contactUs")) +
                            "\n      "
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }